<template>
  <div class="list row">
    <h2>
      Dokumenta: {{ getTitle() }}
      <!-- <Tooltip text="I am  button">
        <div class="small"><i class="bi bi-info-circle"></i></div>
      </Tooltip> -->
    </h2>

    <div class="actions d-flex flex-row-reverse">
      <button
        type="button"
        class="btn btn-primary bi bi-arrow-clockwise"
        @click="refreshData"
      >
        Osveži tabelu
      </button>
      <button type="button" class="btn btn-success bi bi-plus" @click="addData">
        Novi dokument
      </button>
    </div>
    <data-table
      :key="`${$route.params.type}_datatable_key`"
      :url="`invoice/type/${$route.params.type}`"
      :columns="$route.params.type == 'Proform' ? columns : $route.params.type == 'Advance' ? columns2 : columns3"
      :templates="templates"
      :noSort="['price','rest','price_exc', 'payment', 'payment_exc', 'additional.its_no', 'additional.incoming_invoice']"
      :headings="{...headers, extra_actions:  $route.params.type == 'Proform'
          ? 'Upload | AV | KR/R'
          :$route.params.type == 'Advance'
          ? 'Upload | KR/R': 'Upload',}"
      :actions="actions"
      :extra_actions="
        $route.params.type == 'Proform'
          ? extra_actions
          : $route.params.type == 'Advance'
          ? {
              btn1: extra_actions.btn1,
              btn3: extra_actions.btn3,
            }
          : { btn1: extra_actions.btn1 }
      "
      :ref="`${$route.params.type}_datatable`"
      @yes="deleteData"
      :name="$route.params.type"
      @loaded="onLoaded"
      @showComment="showComment"
    >
      <template v-slot:tableExtraOptions>
        <vue-excel-xlsx
          class="ms-5 btn btn-warning bi bi-download"
          :data="jsonData['data']"
          :columns="jsonData['columns']"
          :file-name="getTitle()"
          :file-type="'xlsx'"
          :sheet-name="'Export'"
        >
          Download
        </vue-excel-xlsx>
      </template>
    </data-table>
  </div>
</template>

<script>
import invoiceDataService from "../../services/invoice.service";
import DataTable from "../widgets/DataTable.vue";
// import Tooltip from "../widgets/Tooltip";
import ListDialog from "../widgets/ListDialog.vue";
import dateUtils from "../../utils/date-common";
import ConfirmDialog from "../widgets/ConfirmDialog.vue";
import utils from "../../utils/common";
import con from "../../constants/constants";
import invoiceUtils from "./invoice-utils.js";
import Files from "../files/Files.vue";
import SelectAdvance from "./SelectAdvance.vue";

export default {
  name: "invoice",
  components: {
    DataTable,
    // Tooltip,
  },
  data() {
    return {
      jsonData: [],
      columns: [
        "id",
        "doc_nr",
        "doc_date",
        "Reservation.reservation_num",
        "reservation_num",
        "Customer.name",
        "Station.code",
        "comment2",
        "invoice_status",
        // "price_exc",
        "price",
        "payment",
        "rest",
        "edit",
        "extra_actions",
      ],
      columns2: [
        "id",
        "doc_nr",
        "ref_doc_nr",
        "doc_date",
        "Reservation.reservation_num",
        "reservation_num",
        "Customer.name",
        "Station.code",
        "invoice_status",
        "saop_num",
        "sef_num",
        "price",
        "payment",
        "edit",
        "extra_actions",
      ],
      columns3: [
        "id",
        "doc_nr",
        "ref_doc_nr",
        "doc_date",
        "Reservation.reservation_num",
        "reservation_num",
        "Customer.name",
        "Station.code",
        "invoice_status",
        "saop_num",
        "sef_num",
        "additional.its_no",
        "additional.incoming_invoice",
        "price",
        "payment",
        "edit",
        "extra_actions",
      ],
      headers: {
        id: "ID",
        doc_nr: "Broj",
        ref_doc_nr: "Predhodni dok.",
        doc_date: "Datum",
        "Reservation.reservation_num": "Outbound Broj",
        reservation_num: "Br. Rez / Ugovor",
        "Customer.name": "Kupac",
        "Station.code": "Poslovnica",
        invoice_status: "Status",
        saop_num: "SAOP",
        sef_num: "SEF",
        "additional.its_no": "ITS No.",
        "additional.incoming_invoice": "Ul.Faktura",
        price: "Iznos BRUTO RSD",
        payment: "Uplaćeno RSD",
        rest: "Preostalo RSD",
        comment2: "Komentar",
        edit: "",
        extra_actions: "",
      },
      templates: {
        doc_date(h, row) {
          return dateUtils.formatDate(row.doc_date);
        },
        price(h, row) {
          const sum = row.invoice_items.reduce((accumulator, object) => {
            return Number.parseFloat(
              Number.parseFloat(accumulator) +
                object.price * object.quantity +
                Number.parseFloat(object.tax * object.quantity)
            ).toFixed(2);
          }, 0);
          return Number.parseFloat(sum).toLocaleString("sr", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
        },
        payment(h, row) {
          const payed = row.invoice_payments.reduce((accumulator, object) => {
            return Number.parseFloat(
              Number.parseFloat(accumulator) + Number.parseFloat(object.payment)
            ).toFixed(2);
          }, 0);
          return payed != 0
            ? Number.parseFloat(payed).toLocaleString("sr", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            : "0.00";
        },
        rest(h, row) {
          const sum = row.invoice_items.reduce((accumulator, object) => {
            return Number.parseFloat(
              Number.parseFloat(accumulator) +
                object.price * object.quantity +
                Number.parseFloat(object.tax * object.quantity)
            ).toFixed(2);
          }, 0);
          const payed = row.invoice_payments.reduce((accumulator, object) => {
            return Number.parseFloat(
              Number.parseFloat(accumulator) + Number.parseFloat(object.payment)
            ).toFixed(2);
          }, 0);
          const rest = Number.parseFloat(sum - payed).toFixed(2);
          return rest != 0
            ? Number.parseFloat(rest).toLocaleString("sr", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            : "0.00";
        },
      },
      actions: {
        btn1: {
          color: "primary",
          icon: "bi-pencil",
          action: (data) => {
            this.$router.push({
              path: `/invoice/${this.$route.params.type}/${data.id}`,
              // params: { invoice: { data } },
            });
          },
        },
        deleteItem: {
          action: this.deleteData,
          color: "danger",
          icon: "bi-trash",
        },
        print: {
          color: "success",
          icon: "bi-printer",
          action: (data) => {
            let routeData = this.$router.resolve({
              path: `/invoice/print/${this.$route.params.type}/${data.id}/1`,
            });
            window.open(routeData.href, "_blank");
          },
        },
        print2: {
          color: "success",
          icon: "bi-printer-fill",
          action: (data) => {
            let routeData = this.$router.resolve({
              path: `/invoice/print/${this.$route.params.type}/${data.id}/0`,
            });
            window.open(routeData.href, "_blank");
          },
        },
      },
      extra_actions: {
        btn1: {
          count: "files",
          color: "warning",
          icon: "bi-download",
          action: (data) => this.addFile(data),
        },
        btn2: {
          countIcon: "advanceCount",
          color: "purple",
          icon: "bi-journal-plus",
          // pref:"AR",
          action: (data) => this.showAdvance(data),
        },
        btn3: {
          countIcon: "normalCount",
          color: "indigo",
          icon: "bi-journal-plus",
          // pref:"R",
          action: (data) => this.showNormal(data),
        },
        // print: null,
      },
      currentTutorial: null,
      currentIndex: -1,
      title: "",
    };
  },
  methods: {
    notImplemented(data) {
      utils.showAlert("info", `Opcija ${data} još uvek nije dostupna 🤷`);
    },
    getTitle() {
      return utils.typeName(this.$route.params.type, 2);
    },
    showComment(data) {
      this.$store.commit("app/showModal", {
        modal: ConfirmDialog,
        params: {
          message: data,
          title: "Komentar koji se ne štampa: ",
          noActions: true,
          onYes: () => {
            this.$store.commit("app/hideModal");
          },
          onNo: () => {
            this.$store.commit("app/hideModal");
          },
        },
      });
    },
    showNormal(data) {
      if (data.doc_nr) {
        if (data.normalCount && data.normalCount.length > 0) {
          this.showChildren(data, "Normal");
        } else {
          this.createNormal(data);
        }
      } else {
        utils.showAlert(
          "info",
          "Dokument nije zavrsen. Ne postoje povezani računi"
        );
      }
    },
    showAdvance(data) {
      if (data.doc_nr) {
        if (data.advanceCount > 0) {
          this.showChildren(data, "Advance");
        } else {
          this.createAdvance(data);
        }
      } else {
        utils.showAlert(
          "info",
          "Dokument nije zavrsen. Ne postoje povezani avansni računi"
        );
      }
    },
    createAdvance(data) {
      if (data.invoice_status == "Deleted") {
        utils.showAlert(
          "info",
          "Ne možete kreirati dokumente na osnovu storniranog dokumenta!"
        );
        return;
      }
      if (data.invoice_payments.length == 0) {
        utils.showAlert("info", "Morate uneti uplate da bi kreirali Avans!");
        return;
      }
      if (data.reservation_id) {
        let newData = {
          ref_doc_nr: data.doc_nr,
          exchange: data.exchange,
          exchange_value: data.exchange_value,
          exchange_date: data.exchange_date,
          price: data.invoice_payments.reduce((accumulator, object) => {
            return Number.parseFloat(
              Number.parseFloat(accumulator) + Number.parseFloat(object.payment)
            ).toFixed(2);
          }, 0),
          price_exc: data.invoice_payments.reduce((accumulator, object) => {
            return Number.parseFloat(
              Number.parseFloat(accumulator) +
                Number.parseFloat(object.payment_exc)
            ).toFixed(2);
          }, 0),
          payments: data.invoice_payments,
        };
        invoiceUtils.createAdvanceWithReservation(
          data.reservation_id,
          "Advance",
          newData
        );
      } else {
        invoiceUtils.createAdvance(data.id);
      }
    },
    createNormal(data) {
      if (data.invoice_status == "Deleted") {
        utils.showAlert(
          "info",
          "Ne možete kreirati dokumente na osnovu storniranog dokumenta!"
        );
        return;
      }
      if (data.reservation_id) {
        if (data.invoice_type == "Proform") {
          this.$store.commit("app/showModal", {
            modal: SelectAdvance,
            params: {
              title: "Predačun: " + data.doc_nr,
              proform: data,
              showForm: data.reservation_id,
            },
          });
        } else {
          this.$store.commit("app/showModal", {
            modal: SelectAdvance,
            params: {
              title: "Avans: " + data.doc_nr,
              proform: data,
              showForm: data != null && data.reservation_id,
            },
          });
        }
      } else {
        invoiceUtils.createNormal(data.id);
      }
    },
    addFile(data) {
      this.$store.commit("app/showModal", {
        modal: Files,
        params: {
          pid: data.id,
          parent: "invoices",
          title: "Lista dokumenata",
        },
      });
    },
    async showChildren(data, byType) {
      const res = (byType == "Normal" && data.normalCount && data.normalCount.length > 0)
       ? await invoiceDataService.getByIds(data.normalCount)
       : await invoiceDataService.getByRef(data.doc_nr.replaceAll("/", "|"));
        // .then((res) => {
          const items = res.data.map((invoice) => {
            return invoice.invoice_type == byType
              ? {
                  url: `/invoice/${byType}/${invoice.id}`,
                  label: `Status: <b>${
                    con.statuses[invoice.invoice_status].label
                  }</b> Tip: <b>${con.type[invoice.invoice_type].label}</b> ${
                    invoice.doc_nr ? `Broj: <b>${invoice.doc_nr}</b>` : ""
                  } Iznos: <b>${
                    invoice.invoice_items.length > 0
                      ? invoice.invoice_items.reduce((accumulator, object) => {
                          return Number.parseFloat(
                            Number.parseFloat(accumulator) +
                              object.price_exc * object.quantity
                          ).toFixed(2);
                        }, 0)
                      : 0
                  }</b> Uplaceno: <b>${
                    invoice.invoice_payments.length > 0
                      ? invoice.invoice_payments.reduce(
                          (accumulator, object) => {
                            return Number.parseFloat(
                              Number.parseFloat(accumulator) +
                                Number.parseFloat(object.payment_exc)
                            ).toFixed(2);
                          },
                          0
                        )
                      : 0
                  } </b> `,
                  btnText: "Otvori",
                  url2: `/invoice/print/${invoice.invoice_type}/${invoice.id}/1`,
                  target: "blank",
                  btnText2: "Print",
                  target2: "blank",
                  url3: `/invoice/print/${invoice.invoice_type}/${invoice.id}/0`,
                  btnText3: "Print bez valute",
                  target3: "blank",
                }
              : null;
          });
          const itemsByType = items.filter((item) => item != null);
          if (itemsByType.length < 1) {
            byType == "Advance"
              ? this.createAdvance(data)
              : this.createNormal(data);
          } else {
            this.$store.commit("app/showModal", {
              modal: ListDialog,
              params: {
                title: "Predačun: " + data.doc_nr,
                message: `Povezani${
                  byType == "Advance" ? " avansni" : ""
                } računi za ${data.doc_nr}.`,
                items: itemsByType,
                actions: {
                  btn:
                    byType == "Advance"
                      ? {
                          color: "primary",
                          icon: "bi-journal-plus",
                          label: " Kreiraj avans",
                          action: () => this.createAdvance(data),
                        }
                      : {
                          color: "primary",
                          icon: "bi-journal-plus",
                          label: " Kreiraj racun",
                          action: () => this.createNormal(data),
                        },
                },
              },
            });
          }
        // });
    },
    refreshData() {
      this.$refs[`${this.$route.params.type}_datatable`].onRefresh();
    },
    getJsonData() {
      return this.$refs[`${this.$route.params.type}_datatable`].onDownloadCsv();
    },
    onLoaded(data) {
      this.jsonData["data"] = data.data.rows.map((r) => {
        const price = r.invoice_items.reduce((accumulator, object) => {
          return Number.parseFloat(
            Number.parseFloat(accumulator) +
              object.price * object.quantity +
              Number.parseFloat(object.tax * object.quantity)
          ).toFixed(2);
        }, 0);

        const payment = r.invoice_payments.reduce((accumulator, object) => {
          return Number.parseFloat(
            Number.parseFloat(accumulator) + Number.parseFloat(object.payment)
          ).toFixed(2);
        }, 0);

        const rest = Number.parseFloat(price - payment).toFixed(2);
        return {
          ...r,
          Reservation: r.Reservation ?? {},
          Station: r.Station ?? {},
          Customer: r.Customer ?? {},
          additional: r.additional ?? {},
          price: price,
          payment: payment,
          rest: rest,
        };
      });
      const columns = this.$route.params.type == 'Proform' ? this.columns : this.$route.params.type == 'Advance' ? this.columns2 : this.columns3;
      this.jsonData["columns"] = Object.entries(this.headers)
        .map((k) => {
          if (k[1] && columns.includes(k[0])) {
             return {
              label: k[1],
              field: k[0],
              dataFormat: k[0] == 'price' || k[0] == 'payment' || k[0] == 'rest' ? this.downloadFormat : null,
            };
          }
        })
        .filter((o) => !!o);
    },
    downloadFormat(val) {
      return Number(val);
    },
    addData() {
      this.$router.push({
        path: `/invoice/${this.$route.params.type}/0`,
        params: { data: { invoice: {} } },
      });
    },
    deleteData(data) {
      if (data.invoice_status == "Done") {
        invoiceUtils.stornoDocument(data);
      } else if (data.invoice_status == "New") {
        invoiceUtils.deleteDocument(data);
      } else {
        invoiceUtils.deletedDocument();
      }
    },
  },
  // mounted() {
  //   this.jsonData = this.getJsonData();
  //   console.log({ jsonData: this.jsonData });
  // },
  // updated() {
  //   this.jsonData = [this.getJsonData()];
  //   console.log({ jsonData: this.jsonData });
  // },
};
</script>

<style>
/* h2 {
  position: sticky;
  top: 87px;
  z-index: 1;
  background: white;
}
.actions {
  position: sticky;
  top: 124px;
  z-index: 1;
  background: white;
} */
.Proform_table_row {
  vertical-align: middle;
}
.Proform_table_row > td:nth-child(1),
.Proform_table_row > td:nth-child(3),
.Proform_table_row > td:nth-child(7),
.Proform_table_row > th:nth-child(1),
.Proform_table_row > th:nth-child(3) {
  text-align: center;
}
.Proform_table_row > td:nth-child(11),
.Proform_table_row > td:nth-child(10),
.Proform_table_row > td:nth-child(12),
.Proform_table_row > th:nth-child(11),
.Proform_table_row > th:nth-child(10),
.Proform_table_row > th:nth-child(12) {
  text-align: right;
  font-weight: bold;
}

.Proform_table_row > td:last {
  text-align: left;
}

.Proform_table_row > td:nth-child(6),
.Proform_table_row > td:nth-child(8) {
  min-width: 120px;
  max-width: 250px;
  font-size: small;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.Proform_table_row > td:nth-child(2) {
  min-width: 150px;
  font-size: small;
}

.Advance_table_row {
  vertical-align: middle;
}
.Advance_table_row > td:nth-child(1),
.Advance_table_row > td:nth-child(4),
.Advance_table_row > td:nth-child(8),
.Advance_table_row > th:nth-child(1),
.Advance_table_row > th:nth-child(4) {
  text-align: center;
}

.Advance_table_row > td:nth-child(13),
.Advance_table_row > td:nth-child(12),
.Advance_table_row > th:nth-child(13),
.Advance_table_row > th:nth-child(12) {
  text-align: right;
  font-weight: bold;
}

.Advance_table_row > td:nth-child(2),
.Advance_table_row > td:nth-child(3) {
  min-width: 150px;
  font-size: small;
}

.Advance_table_row > td:nth-child(10),
.Advance_table_row > td:nth-child(11) {
  min-width: 130px;
  font-size: small;
}
.Advance_table_row > td:nth-child(7) {
  min-width: 120px;
  max-width: 250px;
  font-size: small;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Normal_table_row {
  vertical-align: middle;
}

.Normal_table_row > td:nth-child(1),
.Normal_table_row > td:nth-child(4),
.Normal_table_row > td:nth-child(8),
.Normal_table_row > th:nth-child(1),
.Normal_table_row > th:nth-child(4) {
  text-align: center;
}

.Normal_table_row > td:nth-child(14),
.Normal_table_row > td:nth-child(15),
.Normal_table_row > th:nth-child(14),
.Normal_table_row > th:nth-child(15) {
  text-align: right;
  font-weight: bold;
}

.Normal_table_row > td:nth-child(2) {
  min-width: 160px;
  font-size: small;
}
.Normal_table_row > td:nth-child(10),
.Normal_table_row > td:nth-child(11) {
  min-width: 130px;
  font-size: small;
}

.Normal_table_row > td:nth-child(3),
.Normal_table_row > td:nth-child(12),
.Normal_table_row > td:nth-child(13) {
  /* min-width: 130px; */
  font-size: small;
}

.Normal_table_row > td:nth-child(7) {
  min-width: 120px;
  max-width: 250px;
  font-size: small;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
